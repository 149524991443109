export default function (editor) {
    const bm = editor.BlockManager;
    const category = 'Blocks';

    bm.add('v-section', {
        label: 'Section',
        category: category,
        attributes: { class: 'gjs-fonts gjs-f-b3' },
        content: { type: 'section' },
    });

    bm.add('v-btn-link', {
        label: 'Button (link)',
        category: category,
        attributes: { class: 'gjs-fonts gjs-f-button' },
        content: {
            type: 'link',
            content: 'Sign Up',
            attributes: {
                class: 'v-btn v-btn--tile elevation-0 v-size--x-large',
                href: '/',
            },
        }
    });

    bm.add('v-btn-outlined', {
        label: 'Outlined Button (link)',
        category: category,
        attributes: { class: 'gjs-fonts gjs-f-button' },
        content: {
            type: 'link',
            content: 'Outlined Button',
            attributes: {
                class: 'v-btn v-btn--outlined v-size--x-large',
                href: '/',
            },
        }
    });

    bm.add('text', {
        label: 'Paragraph',
        category: category,
        attributes: { class: 'gjs-fonts gjs-f-text' },
        content: {
            type: 'paragraph',
            content: 'Insert your text here',
            activeOnRender: 1
        },
    });

    bm.add('h1', {
        label: 'Heading 1',
        category: category,
        attributes: { class: 'gjs-fonts gjs-f-h1p' },
        content: {
            type: 'heading',
            attributes: { class: 'font--brat font-weight-regular mb-10' },
            content: 'Heading',
            activeOnRender: 1
        },
    });

    bm.add('image', {
        label: 'Image',
        category: category,
        attributes: { class: 'gjs-fonts gjs-f-image' },
        content: {
            type: 'image',
            activeOnRender: 1
        },
    });

    bm.add('global-search-form', {
        label: 'Global Search Form',
        category: category,
        media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 5.5c0-.3-.5-.5-1.3-.5H3.4c-.8 0-1.3.2-1.3.5v3c0 .3.5.5 1.3.5h17.4c.8 0 1.3-.2 1.3-.5v-3zM21 8H3V6h18v2zM22 10.5c0-.3-.5-.5-1.3-.5H3.4c-.8 0-1.3.2-1.3.5v3c0 .3.5.5 1.3.5h17.4c.8 0 1.3-.2 1.3-.5v-3zM21 13H3v-2h18v2z"/><rect width="10" height="3" x="2" y="15" rx=".5"/></svg>',
        content: {
            type: 'globalSearchForm',
        },
    });
}
