import grapesjs from 'grapesjs';
import vBlocks from './gt-blocks';
import customCode from './custom-code';


export default grapesjs.plugins.add('grapesjs-preset-gt', (editor) => {

    // Add gt-blocks
    vBlocks(editor);

    // Add custom code block
    customCode(editor);

});
