<template>
    <v-navigation-drawer
        :mini-variant="miniMutable"
        permanent app clipped
    >
        <v-list-item class="px-2">
            <v-btn
                icon
                @click="toggleDrawer"
                class="ml-auto"
                >
                <v-icon class="primary--text" large v-show="! miniMutable">mdi-chevron-left</v-icon>
                <v-icon class="primary--text" large v-show="miniMutable">mdi-chevron-right</v-icon>
            </v-btn>
        </v-list-item>
        <div class="sidebar">
            <v-list-item class="px-2" link :to="{name: 'admin.profile'}">
                <v-list-item-avatar>
                    <v-img :src="$auth.user() && $auth.user().avatar_url"></v-img>
                </v-list-item-avatar>
                <v-list-item-title>
                    {{ $auth.user().name }}
                </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list dense>
                <v-list-item exact link :to="link.to" v-for="link in menu" :key="JSON.stringify(link)">
                    <v-list-item-icon>
                        <v-icon :title="link.text">{{link.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{link.text}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>
    </v-navigation-drawer>
</template>

<script>
    import menu from "./menu";

    export default {
        name: "AdminDrawerMenu",
        props: ['mini'],
        data () {
            return {
                menu: menu,
                miniMutable: this.$vuetify.breakpoint.xs || this.mini
            }
        },
        computed: {
            isMobile() {
                return this.$vuetify.breakpoint.xs
            },
        },
        watch: {
            isMobile() {
                this.miniMutable = this.isMobile || this.$root.mini
            },
        },
        methods: {
            toggleDrawer() {
                this.$root.$emit('toggleDrawer');
                this.miniMutable = ! this.miniMutable;
            }
        },
    }
</script>

<style scoped>

</style>
