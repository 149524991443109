import loadComponents from './components';
import loadTraits from './traits';
import loadBlocks from './blocks';
import panels from './panels';
import commands from './commands';
import styles from './styles';

export default function (editor) {

    loadComponents(editor);
    loadTraits(editor);
    loadBlocks(editor);
    panels(editor);
    commands(editor);
    styles(editor);
}

