
export default function (editor) {
    const domc = editor.DomComponents;

    const nameTrait = {
        name: 'name',
    };
    const refTrait = {
        name: 'ref',
    };
    const placeholderTrait = {
        name: 'placeholder',
    };
    const requiredTrait = {
        type: 'checkbox',
        name: 'required',
    };
    const forTrait = {
        name: 'for',
    };

    // Section
    domc.addType('section', {
        isComponent: el => el.classList && el.classList.contains('container'),
        model: {
            defaults: {
                tagName: 'section',
                name: 'Section',
                components: {
                    type: 'container',
                    attributes: { class: 'container container--fluid' },
                    components: [
                        {
                            type: 'row',
                            components: [
                                { type: 'col' },
                                { type: 'col' },
                                { type: 'col' },
                            ],
                        },
                    ],
                }
            }
        }
    });

    // Container
    domc.addType('container', {
        isComponent: el => el.classList && el.classList.contains('container'),
        model: {
            defaults: {
                name: 'v-container',
                attributes: { class: 'container' },
            }
        }
    });

    // Row
    domc.addType('row', {
        isComponent: el => el.classList && el.classList.contains('row'),
        model: {
            defaults: {
                name: 'v-row',
                tagName: 'div',
                attributes: { class: 'row' },
                droppable: true,
            }
        }
    });

    // Col
    domc.addType('col', {
        isComponent: el => el.classList && el.classList.contains('col'),
        model: {
            defaults: {
                name: 'v-col',
                tagName: 'div',
                attributes: { class: 'col' },
                components: [
                    {
                        type: 'text',
                        components: 'Column Content'
                    },
                ]
            }
        }
    });

    // Paragraph
    domc.addType('paragraph', {
        isComponent: el => el.tagName == 'P',
        extend: 'text',
        model: {
            defaults: {
                tagName: 'p',
            },
            init() {
                // Ovverride `init` function in `text`
            }
        },

        // Update the view, if you need
        view: {},
    });

    // Heading
    domc.addType('heading', {
        isComponent: el => el.tagName == 'H1',
        extend: 'text',
        model: {
            defaults: {
                name: 'Heading',
                tagName: 'h1',
            },
            init() {
                // Ovverride `init` function in `text`
            }
        },

        // Update the view, if you need
        view: {},
    });

    // FORM
    domc.addType('form', {
        isComponent: el => el.tagName == 'FORM',

        model: {
            defaults: {
                tagName: 'form',
                droppable: ':not(form)',
                draggable: ':not(form)',
                attributes: { method: 'get' },
                traits: [
                    {
                        name: 'id',
                    },
                    {
                        type: 'select',
                        name: 'method',
                        options: [
                            { value: 'get', name: 'GET' },
                            { value: 'post', name: 'POST' },
                        ],
                    },
                    {
                        name: 'action',
                    }
                ],
            },
        },

        view: {
            events: {
                submit: e => e.preventDefault(),
            }
        },
    });

    // INPUT
    domc.addType('input', {
        isComponent: el => el.tagName == 'INPUT',

        model: {
            defaults: {
                tagName: 'input',
                draggable: 'form, form *',
                droppable: false,
                highlightable: false,
                attributes: { type: 'text' },
                traits: [
                    nameTrait,
                    refTrait,
                    placeholderTrait,
                    {
                        type: 'select',
                        name: 'type',
                        options: [
                            { value: 'text' },
                            { value: 'email' },
                            { value: 'password' },
                            { value: 'number' },
                        ]
                    },
                    requiredTrait
                ],
            },
        },

        extendFnView: ['updateAttributes'],
        view: {
            updateAttributes() {
                this.el.setAttribute('autocomplete', 'off');
            },
        }
    });

    // LABEL
    domc.addType('label', {
        extend: 'text',
        isComponent: el => el.tagName == 'LABEL',

        model: {
            defaults: {
                tagName: 'label',
                components: 'Label',
                traits: [forTrait],
            },
        },
    });

    // Search Form
    domc.addType('globalSearchForm', {
        model: {
            defaults: {
                tagName: 'form',
                attributes: { id: 'globalSearchForm', class: 'global-search-form' },
                copyable: false,
                droppable: false,
                unstylable: true,
                content: `<div class="container container--fluid">
                            <div class="row">
                                <div class="col d-flex col-2 pa-0">
                                    <input type="text" name="date" placeholder="Date" class="white col-12 pa-0 pa-2">
                                </div>
                                <div class="d-flex col-3 pa-0" >
                                    <input type="text" name="city" placeholder="City/State" class="white col-12 pa-0 pa-2">
                                </div>
                                <div class="col d-flex pa-0">
                                    <input type="text" name="text" placeholder="Search for Events, Artist or Destination" class="white col-12 pa-0 pa-2">
                                </div>
                                <div class="col-auto text-right pa-0">
                                    <a href="/" title="Search" class="v-btn v-btn--tile elevation-0 py-2 px-4 white--text error">SEARCH</a>
                                </div>
                            </div>
                        </div>`,
            },
        },
    });

    // Card

}
