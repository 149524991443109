export default (editor) => {
    const sm = editor.StyleManager;

    sm.getSectors().reset([
        {
            name: 'Layout',
            open: false,
            buildProps: [
                'float',
                'width',
                'height',
                'min-width',
                'max-width',
                'min-height',
                'max-height',
            ],
        },
        {
            name: 'Margin & padding',
            open: false,
            buildProps: [
                'margin',
                'padding',
            ],
        },
        {
            name: 'Typography',
            open: false,
            buildProps: [
                'color',
                'font-family',
                'font-size',
                'line-height',
                'font-weight',
                'letter-spacing',
                'text-align',
                'text-shadow',
            ],
            properties: [
                {
                    property: 'text-align',
                    list: [
                        { value: 'left', className: 'fa fa-align-left' },
                        { value: 'center', className: 'fa fa-align-center' },
                        { value: 'right', className: 'fa fa-align-right' },
                        { value: 'justify', className: 'fa fa-align-justify' },
                        { value: '', className: 'fa fa-ban' },
                    ],
                },
                {
                    property: 'font-family',
                    defaults: 'Roboto, sans-serif',
                    list: [
                        { value: '', name: '' },
                        { value: 'Roboto, sans-serif', name: 'Roboto' },
                        { value: 'Brat, sans-serif', name: 'Brat' },
                        { value: 'AntiqueOlive, sans-serif', name: 'AntiqueOlive' },
                    ],
                },
            ]
        },
        {
            name: 'Background',
            open: false,
            buildProps: [
                'background-color',
                'background',
            ],
        },
        {
            name: 'Border',
            open: false,
            buildProps: [
                'border',
                'border-radius-c',
            ],
        },
    ]);
}
