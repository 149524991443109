<template>
    <div>
        <v-row class="pl-16 pt-2 align-center">
            <v-col cols="auto">
                <h2>{{page.slug}}</h2>
            </v-col>
            <v-col>
                <v-btn @click="handleSave" tile large color="success">
                    <v-icon left>mdi-content-save-outline</v-icon>
                    Save
                </v-btn>
            </v-col>
        </v-row>
        <admin-drawer-menu :mini="true"/>
        <v-row class="pl-16">
            <v-col v-if="!loading" class="pl-2">
                <div id="gjs"></div>
            </v-col>
            <v-col v-else>
                <v-skeleton-loader type="list-item-avatar-three-line, image, article"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import AdminDrawerMenu from "@/components/admin/AdminDrawerMenu";
import grapesjs from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import '@/plugins/grapesjs-preset-gt'

export default {
    name: "gjs-editor",
    components: {
        AdminDrawerMenu
    },
    props: {
        Model: {
            type: Function,
            default: () => null
        },
        pageId: {
            type: [String, Number],
            default: () => null
        },
    },
    data: function () {
        return {
            page: {},
            loading: true,
            editor: {},
            assetManagerOptions: {
                upload: '/api/upload',
                uploadName: 'file',
                multiUpload: false,
                headers: {
                    Authorization: null
                },
                params: {
                    directory: '/pages'
                },
            },
        }
    },
    methods: {
        async handleSave() {
            this.page.html = this.editor.getHtml({cleanId: true})
            this.page.css = this.editor.store().css
            this.page.components = this.editor.store().components
            this.page.assets = this.editor.store().assets
            this.page.styles = this.editor.store().styles
            let page = new this.Model(this.page)
            await page.save()
        },
        initGjs() {
            this.initEditor()
            this.applyThemeStyle()
            this.applyCustomStyle()
        },

        getVuetifyStyleSheet() {
            return document.getElementById('vuetify-theme-stylesheet');
        },

        getCustomStyleElement() {
            const elements = [...document.getElementsByTagName('style')];

            return elements.filter(style => style.textContent.includes('.grubtunes-custom-styles'));
        },

        getCanvasHead() {
            return this.editor.Canvas.getDocument().getElementsByTagName('head')[0];
        },

        initEditor() {
            this.editor = grapesjs.init({
                container: '#gjs',
                components: JSON.parse(this.page.components),
                style: JSON.parse(this.page.styles),
                plugins: ["grapesjs-preset-gt"],
                protectedCss: '',
                storageManager: {
                    autoload: false,
                },
                assetManager: {
                    assets: JSON.parse(this.page.assets),
                    ...this.assetManagerOptions,
                },
                selectorManager: {
                    componentFirst: 1, // select for styling only Component
                    render: ({ el }) => {
                        el.querySelector('[data-sync-style]').remove() // disable ability style Classes
                    }
                },
                styleManager: {
                    sectors: [],
                },
                canvas: {
                    styles: [
                        'https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css',
                        'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css',
                    ]
                },
            });
            this.editor.on('asset:upload:response', this.addFileToAssetManager);
            // Set Editor Canvas
            this.editor.DomComponents.getWrapper().set('attributes', {'class': 'v-application v-application--wrap main'});
            this.editor.DomComponents.getWrapper().set('selectable', false);
        },

        addFileToAssetManager(response) {
            this.editor.AssetManager.add([
                {
                    name: response.original_name,
                    src: `/storage/${response.path}`,
                }
            ]);
        },

        applyThemeStyle() {
            const canvasHead = this.getCanvasHead();
            const vuetifyThemeStylesheet = this.getVuetifyStyleSheet();

            if (!vuetifyThemeStylesheet) {
                return;
            }

            const themeStyle = document.createElement('style');

            themeStyle.type = 'text/css';
            themeStyle.textContent = vuetifyThemeStylesheet.textContent;
            canvasHead.append(themeStyle);
        },

        applyCustomStyle() {
            const canvasHead = this.getCanvasHead();
            const customStyleElement = this.getCustomStyleElement()

            if (!customStyleElement.length) {
                return;
            }

            const customStyle = document.createElement('style');

            customStyle.type = 'text/css';
            customStyle.textContent = customStyleElement[0].textContent;
            canvasHead.append(customStyle);
        },

        async setDropzoneAuthToken() {
            const token = await localStorage.getItem('auth_token_default')
            this.assetManagerOptions.headers.Authorization = "Bearer " + token
        },
    },
    async mounted() {
        await this.setDropzoneAuthToken()

        this.loading = true
        this.page = await this.Model.find(this.pageId)
        this.loading = false

        this.$nextTick(this.initGjs)
    },
}
</script>
<style scoped>

</style>
