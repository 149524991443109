export default [
    {
        to: {name: 'admin.index'},
        icon: 'mdi-home',
        text: 'Dashboard',
    },
    {
        to: {name: 'admin.coupons.index'},
        icon: 'mdi-ticket-percent',
        text: 'Coupons',
    },
    {
        to: {name: 'dashboard.chats'},
        icon: 'mdi-message-text',
        text: 'Chats',
    },
    {
        to: {name: 'dashboard.favourites'},
        icon: 'mdi-heart',
        text: 'Favorites',
    },
    {
        to: {name: 'admin.price-plans.index'},
        icon: 'mdi-application-cog',
        text: 'Price Plans',
    },
    {
        to: {name: 'admin.users.index'},
        icon: 'mdi-account',
        text: 'Users',
    },
    {
        to: {name: 'admin.fans.index'},
        icon: 'mdi-account',
        text: 'Fans',
    },
    {
        to: {name: 'admin.artists.index'},
        icon: 'mdi-account-music',
        text: 'Artists',
    },
    {
        to: {name: 'admin.artist-submissions.index'},
        icon: 'mdi-email',
        text: 'Artist Submissions',
    },
    {
        to: {name: 'admin.venues.index'},
        icon: 'mdi-table-chair',
        text: 'Venues',
    },
    {
        to: {name: 'admin.venue-submissions.index'},
        icon: 'mdi-email',
        text: 'Venue Submissions',
    },
    {
        to: {name: 'admin.venue-claim-requests.index'},
        icon: 'mdi-email',
        text: 'Venue Claim Requests',
    },
    {
        to: {name: 'admin.tracks.index'},
        icon: 'mdi-playlist-music',
        text: 'Tracks',
    },
    {
        to: {name: 'admin.videos.index'},
        icon: 'mdi-video',
        text: 'Videos',
    },
    {
        to: {name: 'admin.gigs.index'},
        icon: 'mdi-calendar',
        text: 'Gigs',
    },
    {
        to: {name: 'admin.genres.index'},
        icon: 'mdi-account',
        text: 'Genres',
    },
    {
        to: {name: 'admin.food-types.index'},
        icon: 'mdi-food',
        text: 'Food Types',
    },
    {
        to: {name: 'admin.venue-features.index'},
        icon: 'mdi-paw',
        text: 'Venue Features',
    },
    {
        to: {name: 'admin.email-templates.index'},
        icon: 'mdi-mail',
        text: 'Email Templates',
    },
    {
        to: {name: 'admin.blocks.index'},
        icon: 'mdi-cash-100',
        text: 'Blocks',
    },
    {
        to: {name: 'admin.pages.index'},
        icon: 'mdi-book-open-page-variant-outline',
        text: 'Pages',
    },
    {
        to: {name: 'admin.help.index'},
        icon: 'mdi-help-circle-outline',
        text: 'Help',
    },
]
